import React, { useState } from 'react';
import { Modal, Select, Button, Typography, Tag } from 'antd';

const { Text } = Typography;

const DeleteAgentModal = ({ agentContent, creatorOptions, onConfirm, onCancel }) => {
  const [transferSelections, setTransferSelections] = useState([]);

  const handleSelectionChange = (brand, value) => {
    const transfer = { brand, user: value, agent: agentContent?.agent };
    // Add to transferSelections if not already present
    if (!transferSelections.some((t) => t.brand === brand)) {
      setTransferSelections([...transferSelections, transfer]);
    } else {
      // Update the existing transfer
      setTransferSelections(transferSelections.map((t) => (t.brand === brand ? transfer : t)));
    }
  };

  const handleConfirm = () => onConfirm(transferSelections);

  return (
    <Modal
      title={<h3 style={{ fontSize: '1.7em', fontWeight: 600, textAlign: 'center' }}>Delete Agent Support</h3>}
      open={true}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleConfirm}
          disabled={transferSelections.length !== agentContent.brands.length} // Ensure all transfers are made
        >
          Transfer ownership and Delete Agency User
        </Button>
      ]}
    >
      <div>
        <p>
          <Text strong>Agency:</Text> {agentContent.agencyName}
        </p>
        <p>
          <Text strong>User:</Text> {agentContent.agentName}
        </p>
        <p>
          <Text strong>Branch:</Text> {agentContent.branchName}
        </p>
        <p>
          <Text strong>Brands:</Text>{' '}
          {agentContent.brands.map((brand, index) => (
            <Tag key={index} color="orange">
              {brand.name}
            </Tag>
          ))}
        </p>

        <Text type="danger" style={{ display: 'block', marginBottom: '10px' }}>
          Note: this user has associated data in Qoolo. Before deleting this user, please select a Creator for each Brand to transfer ownership of this data. For custom needs, please reach out to support@qoolo.com.
        </Text>

        {agentContent.brands.map((brand, index) => (
          <div key={index} style={{ marginBottom: '10px' }}>
            <Text strong>{brand.name}</Text>
            <Select
              style={{ width: '100%', marginTop: '5px' }}
              placeholder="Select Creator for Data Transfer"
              onChange={(value) => handleSelectionChange(brand._id, value)}
            >
              {creatorOptions.map((creator) => {
                if (creator.brand.includes(brand._id)) return (
                  <Select.Option key={creator._id} value={creator._id}>
                    {creator.firstName} {creator.lastName} ({creator.email})
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default DeleteAgentModal;
